import { Injectable } from '@angular/core';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { SentryUtilsService } from '../utilities/sentry-utils.servic';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})

export class CommonService {

  constructor(
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
    private _sentryUtilsService: SentryUtilsService
  ) { }

  downloadFile(response: HttpResponse<Blob>, filename: string) {
    const blob = new Blob([response.body], { type: response.body.type });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(url);
  }

  buildParams(params: { [key: string]: any } = {}): HttpParams {
    let queryParams = new HttpParams();
    Object.keys(params).forEach(key => {
      const value = params[key];
      if (value !== null && value !== undefined) {
        if (Array.isArray(value)) {
          value.forEach(item => {
            queryParams = queryParams.append(key, item);
          });
        } else {
          queryParams = queryParams.append(key, value);
        }
      }
    });
    return queryParams;
  }

  handleErrorForGetNotFound(error: any) {
    if (error.status !== 404) {
      this._toastrService.error(error.error.detail, error.error.title);
      this._sentryUtilsService.sendEventErrorForSentry(error);
      console.error('Critical error:', error);
    }
    return of(null);
  }

  handleErrorForGet(error: any) {
    if (error.status !== 404) {
      this._sentryUtilsService.sendEventErrorForSentry(error);
      console.log('Critical error:', error);
    }
    this._toastrService.error(error.error.detail, error.error.title);
    return of(null);
  }

  handleError(error: any, alert: boolean = true) {
    if (error.status === 404 || error.status === 403 || error.status === 401) {
      this._sentryUtilsService.sendEventInfoForSentry(error);
      console.warn('Error 404: ', error);
    } else {
      if (alert) {
        this._toastrService.error(this._translateService.instant(error.error.detail), this._translateService.instant(error.error.title));
      }
      this._sentryUtilsService.sendEventErrorForSentry(error);
      console.error('Critical error:', error);
    }
    return of(null);
  }

  hanleErrorImage(error: any) {
    if (error.status !== 404) {
      this._toastrService.error(`${error.status} ${error.statusText}`);
      this._sentryUtilsService.sendEventErrorForSentry(error);
      console.error('Critical error:', error);
    }
    return of(null);
  }

  handleErrorDownload(error: any) {
    if (error.status === 404) {
      this._toastrService.error(this._translateService.instant('MESSAGES.NOT_FOUND_FILE'));
      this._sentryUtilsService.sendEventInfoForSentry(error);
      console.warn('Error 404: ', error);
    } else {
      this._toastrService.error(`${error.status} ${error.statusText}`);
      this._sentryUtilsService.sendEventErrorForSentry(error);
      console.error('Critical error:', error);
    }
    return of(null);
  }
}