import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DropdownOption } from '../dropdown.interface';
import { MakesService } from 'src/app/shared/api-services/makes.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'dropdown-primary-vehicle-type',
    template: `
    <dropdown-primary
      class="w-100"
      [isDisabled]="isDisable"
      [selectedOption]="optionSelected"
      [options]="listOptions"
      (selectedOptionChange)="onSelected($event)"
      [emitNgOnInit]="false"
      [activeScrollbar]="true"
      [iconClass]="'bi bi-chevron-down'"
      [cssClass]="'btn-dropdown'">
    </dropdown-primary>
  `,
    standalone: false
})
export class DropdownPrimaryVehicleTypeComponent implements OnInit, OnChanges {
  @Input() vehicleTypeId: string;
  @Input() isDisable: boolean = false;

  defaultOption: DropdownOption = {
    value: '',
    text: this._translateService.instant('SELECT_OPTION')
  };

  optionSelected: DropdownOption;
  listOptions: DropdownOption[]; 
  
  @Output() onSelectedEvent = new EventEmitter<DropdownOption>();

  private _destroy = new Subject<void>();

  constructor(
    private _makesService: MakesService,
    private _translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.optionSelected = this.defaultOption;
    this.getData();
  }

  ngOnChanges() {
    if (!this.listOptions) {
      this.getData();
    }
  }

  getData() {
    this._makesService.listVehicleTypes$()
      .pipe(takeUntil(this._destroy))
      .subscribe({
        next: response => {
          this.listOptions = response.items.map(item => {
            return <DropdownOption> {
              value: item.id,
              text: item.name,
              extraField: {
                text: item.name,
                item: item
              }
            }
          });

          if (this.vehicleTypeId) {
            this.optionSelected = this.listOptions.find(option => option.value === this.vehicleTypeId);
            this.onSelectedEvent.emit(this.optionSelected);
          } else {
            this.optionSelected = this.defaultOption;
          }
        }
      });
  }

  onSelected(optionSelected$: DropdownOption) {
    this.onSelectedEvent.emit(optionSelected$);
  }

  onReset() {
    this.optionSelected = this.defaultOption;
  }
}
