import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { DropdownOption } from '../dropdown.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'dropdown-primary-active',
    template: `
    <dropdown-primary
      class="w-100"
      [isDisabled]="isDisable"
      [selectedOption]="optionSelected"
      [options]="listOptions"
      (selectedOptionChange)="onSelected($event)"
      [emitNgOnInit]="false"
      [activeScrollbar]="true"
      [iconClass]="'bi bi-chevron-down'"
      [cssClass]="'btn-dropdown'">
    </dropdown-primary>
  `,
    standalone: false
})
export class DropdownPrimaryActiveComponent implements OnInit {
  @Input() isDisable: boolean = false;

  optionSelected: DropdownOption;
  listOptions: DropdownOption[]; 
  
  @Output() onSelectedEvent = new EventEmitter<DropdownOption>();

  constructor(
    private _translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.listOptions = this._translateService.instant('ACTIVE_FILTERS');
    this.setDefaultOption();
  }

  private setDefaultOption() {
    this.optionSelected = JSON.parse(JSON.stringify(this.listOptions.find(option => option.value === 'all')));
  }

  onSelected(optionSelected$: DropdownOption) {
    this.onSelectedEvent.emit(optionSelected$);
  }

  onReset() {
    this.setDefaultOption();
  }
}
