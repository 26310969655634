import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapitalizePipe } from './capitalize.pipe';
import { DynamicHighlightPipe } from './dynamic-highlight.pipe';
import { TruncatePipe } from './truncate.pipe';
@NgModule({
  declarations: [
    CapitalizePipe,
    DynamicHighlightPipe,
    TruncatePipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CapitalizePipe,
    DynamicHighlightPipe,
    TruncatePipe,
  ]
})
export class PipeModule { }
