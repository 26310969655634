import * as Sentry from "@sentry/angular";
import { Injectable } from '@angular/core';
import { TenantService } from '../api-services/tenant.service';

@Injectable({
  providedIn: 'root'
})
export class SentryUtilsService {
  tenantId = this._tenatService.getTenantId();

  constructor(
    private _tenatService: TenantService,
  ) { }

  sendEventErrorForSentry(error: any, extraInfo?: any) {
    Sentry.withScope(scope => {
      scope.setExtra("tenantId", this.tenantId);
      scope.setExtra("extra", extraInfo ?? null);
      Sentry.captureEvent(error);
    });
  }

  sendEventDebugForSentry(error: any, extraInfo?: any) {
    Sentry.withScope(scope => {
      scope.setExtra("tenantId", this.tenantId);
      scope.setExtra("extra", extraInfo ?? null);
      scope.setLevel("debug");
      Sentry.captureEvent(error);
    });
  }

  sendEventInfoForSentry(error: any, extraInfo?: any) {
    Sentry.withScope(scope => {
      scope.setExtra("tenantId", this.tenantId);
      scope.setExtra("extra", extraInfo ?? null);
      scope.setLevel("info");
      Sentry.captureEvent(error);
    });
  }
}