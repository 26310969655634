import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { DropdownOption } from '../dropdown.interface';
import { MakesService } from 'src/app/shared/api-services/makes.service';

@Component({
    selector: 'dropdown-primary-make',
    template: `
    <dropdown-primary
      class="w-100"
      [isDisabled]="isDisable"
      [selectedOption]="optionSelected"
      [options]="listOptions"
      (selectedOptionChange)="onSelected($event)"
      [emitNgOnInit]="false"
      [activeScrollbar]="true"
      [iconClass]="'bi bi-chevron-down'"
      [cssClass]="'btn-dropdown'">
    </dropdown-primary>
  `,
    standalone: false
})
export class DropdownPrimaryMakeComponent {
  @Input() makeId: string;
  @Input() isDisable: boolean = false;

  tenantId: string = this._tenantService.getTenantId();

  defaultOption: DropdownOption = {
    value: '',
    text: this._translateService.instant('SELECT_OPTION')
  };

  optionSelected: DropdownOption;
  listOptions: DropdownOption[]; 
  
  @Output() onSelectedEvent = new EventEmitter<DropdownOption>();

  constructor(
    private _tenantService: TenantService,
    private _makesService: MakesService,
    private _translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.optionSelected = this.defaultOption;
    this.getData();
  }

  ngOnChanges() {
    if (!this.listOptions) {
      this.getData();
    }
  }

  getData() {
    this._makesService.listMakes$(this.tenantId).subscribe({
      next: response => {
        this.listOptions = response.items.map(make => {
          return {
            value: make.id,
            text: make.name
          }
        });

        if (this.makeId) {
          this.optionSelected = this.listOptions.find(option => option.value === this.makeId);
          this.onSelectedEvent.emit(this.optionSelected);
        } else {
          this.optionSelected = this.defaultOption;
        }
      }
    });
  }

  onSelected(optionSelected$: DropdownOption) {
    this.onSelectedEvent.emit(optionSelected$);
  }
}
