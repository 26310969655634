import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { DropdownOption } from '../dropdown.interface';
import { MakesService } from 'src/app/shared/api-services/makes.service';

@Component({
    selector: 'dropdown-primary-model',
    template: `
    <dropdown-primary
      class="w-100"
      [isDisabled]="isDisabled || intenalDisable"
      [selectedOption]="optionSelected"
      [options]="listOptions"
      (selectedOptionChange)="onSelected($event)"
      [emitNgOnInit]="false"
      [activeScrollbar]="true"
      [iconClass]="'bi bi-chevron-down'"
      [cssClass]="'btn-dropdown'">
    </dropdown-primary>
  `,
    standalone: false
})
export class DropdownPrimaryModelComponent {
  @Input() modelId: string;
  @Input() makeId: string;
  previousMakeId: string;

  @Input() isDisabled: boolean = false;
  intenalDisable: boolean = true;

  tenantId: string = this._tenantService.getTenantId();

  defaultOption: DropdownOption = {
    value: '',
    text: this._translateService.instant('SELECT_OPTION')
  };

  optionSelected: DropdownOption;
  listOptions: DropdownOption[]; 

  
  @Output() onSelectedEvent = new EventEmitter<DropdownOption>();

  constructor(
    private _tenantService: TenantService,
    private _makesService: MakesService,
    private _translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.optionSelected = this.defaultOption;
  }

  ngOnChanges() {
    this.intenalDisable = this.makeId && this.makeId !== '' ? false : true;
    if (this.makeId && this.makeId !== '' && this.previousMakeId !== this.makeId) {
      this.previousMakeId = this.makeId;
      this.optionSelected = this.defaultOption;
    }
    this.getModels();
  }

  getModels() {
    let params = {
      makeId: this.makeId
    }
    if (this.makeId && this.makeId !== '' && this.optionSelected === this.defaultOption) {
      this._makesService.listModels$(this.tenantId, params).subscribe({
        next: response => {
          this.listOptions = response.items.map(model => {
            return {
              value: model.id,
              text: model.name
            }
          });
  
          if (this.modelId) {
            this.optionSelected = this.listOptions.find(model => model.value === this.modelId);
            this.onSelectedEvent.emit(this.optionSelected);
          }
        }
      });
    }
  }

  onSelected(optionSelected$: DropdownOption) {
    this.onSelectedEvent.emit(optionSelected$);
  }
}
