import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import { CommonService } from './common.service';
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MappingModel, UserFileModel, UserModel } from '../models/user/users';
import { UserDataByTagFilter, UserDataFilter, UserFieldFilter, UserFilter, UsersFilter } from '../models/user/user-filter';
import { UserFileRequest, UserRequest } from '../models/user/user-request';
import { UserDataByTagResponse, UserDataResponse, UserFieldResponse, UserFileResponse, UserModelResponse } from '../models/user/user-response';
import { TenantService } from './tenant.service';

@Injectable({
  providedIn: 'root'
})

export class UsersService {
  private _baseUrl = VECTORE_API_BASE_URL;
  private _tenantId: string;
  private _userImageCache = new Map<string, Observable<any>>();

  constructor(
    private _tenantService: TenantService,
    private _http: HttpClient,
    private _commonService: CommonService,
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
    private _domSanitizer: DomSanitizer,
  ) { 
    this._tenantId = this._tenantService.getTenantId();
  }

  listUserFields$(params?: UserFieldFilter): Observable<UserFieldResponse> {
    return this._http.get<UserFieldResponse>(`${this._baseUrl}/${this._tenantId}/user-fields`,
      { params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  createUser$(tenantId: string, userData: UserRequest): Observable<UserModel> {
    return this._http.post<UserModel>(`${this._baseUrl}/${tenantId}/users`, userData)
      .pipe(
        tap({
          next: response => {
            if (response.id) {
              this._toastrService.success(this._translateService.instant('PERSONS.MESSAGES.SUCCESS_CREATED'));
            }
          },
          error: error => {
            this._toastrService.error(this._translateService.instant('PERSONS.MESSAGES.' + error.error.errorCode));
            this._commonService.handleError(error);
          }
        })
      );
  }

  listUsers$(params: UsersFilter): Observable<UserModelResponse> {
    let paramsToSend = params ? params : {};
    paramsToSend.includeHidden = false;
    return this._http.get<UserModelResponse>(`${this._baseUrl}/${this._tenantId}/users`,
      { params: this._commonService.buildParams(params) });
  }

  getUser$(tenantId: string, userId: string, params?: UserFilter): Observable<UserModel> {
    return this._http.get<UserModel>(`${this._baseUrl}/${this._tenantId ?? tenantId}/users/${userId}`,
      { params: this._commonService.buildParams(params) });
  }

  updateUser$(tenantId: string, userId: string, userData?: UserRequest): Observable<UserModel> {
    return this._http.patch<UserModel>(`${this._baseUrl}/${tenantId}/users/${userId}`, userData)
      .pipe(
        tap({
          next: response => {
            if (response.id) {
              this._toastrService.success(this._translateService.instant('PERSONS.MESSAGES.SUCCESS_UPDATED'));
            }
          },
          error: error => {
            this._toastrService.error(this._translateService.instant('PERSONS.MESSAGES.' + error.error.errorCode));
            this._commonService.handleError(error);
          }
        })
      )
  }

  deleteUser$(tenantId: string, userId: string): Observable<HttpResponse<Object>> {
    return this._http.delete(`${this._baseUrl}/${tenantId}/users/${userId}`, { observe: 'response' })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getUserImage$(tenantId: string, userId: string, width?: number, height?: number, safe: boolean = true): Observable<SafeUrl | string | null> {
    if (!userId) {
      return of(null);
    }

    const cacheKey = `${tenantId}-${userId}-${width}-${height}`;

    if (!this._userImageCache.has(cacheKey)) {
      const params = {
        width: width,
        height: height
      };
      const requestObservable = this._http.get<any>(`${this._baseUrl}/${tenantId}/users/${userId}/image`, {
        params: this._commonService.buildParams(params),
        responseType: 'blob' as 'json'
      }).pipe(
        catchError(error => this._commonService.hanleErrorImage(error))
      );

      this._userImageCache.set(cacheKey, requestObservable);
    }
    return this._userImageCache.get(cacheKey).pipe(
      map(response => {
        if (response) {
          let urlCreator = window.URL || window.webkitURL;
          if (!safe) {
            return urlCreator.createObjectURL(response);
          }
          return this._domSanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(response));
        }
        return null;
      })
    );
  }

  updateUserGroups$(tenantId: string, userId: string, userGroupData: string[]): Observable<UserModel> {
    return this._http.post<UserModel>(`${this._baseUrl}/${tenantId}/users/${userId}/update-groups`, userGroupData);
  }

  exportUsers$(tenantId: string, exportData: MappingModel, params?: UsersFilter): Observable<HttpResponse<Blob>> {
    let paramsToSend = params ? params : {};
    paramsToSend.includeHidden = false;

    return this._http.post(`${this._baseUrl}/${tenantId}/export/users`, exportData,
      { observe: 'response', responseType: 'blob', params: this._commonService.buildParams(paramsToSend) })
      .pipe(
        tap({
          next: response => {
            const now = new Date();
            const filename = `export_users_${now.toLocaleDateString('it-IT')}.xlsx`;
            this._commonService.downloadFile(response, filename);
          },
          error: error => this._commonService.handleError(error)
        })
      );
  }

  addUserFile$(tenantId: string, userId: string, fileData: UserFileRequest): Observable<HttpResponse<UserFileModel>> {
    return this._http.post<UserFileModel>(`${this._baseUrl}/${tenantId}/users/${userId}/files`, fileData, { observe: 'response' });
  }

  listUserFiles$(tenantId: string, userId: string, params?: any): Observable<UserFileResponse> {
    return this._http.get<UserFileResponse>(`${this._baseUrl}/${tenantId}/users/${userId}/files`, { params: this._commonService.buildParams(params) });
  }

  getUserFile$(tenantId: string, userId: string, userFileId: string): Observable<UserFileModel> {
    return this._http.get<UserFileModel>(`${this._baseUrl}/${tenantId}/users/${userId}/files/${userFileId}`);
  }

  updateUserFile$(tenantId: string, userId: string, userFileId: string, fileData: UserFileRequest): Observable<UserFileModel> {
    return this._http.patch<UserFileModel>(`${this._baseUrl}/${tenantId}/users/${userId}/files/${userFileId}`, fileData);
  }

  deleteUserFile$(tenantId: string, userId: string, userFileId: string): Observable<HttpResponse<Object>> {
    return this._http.delete(`${this._baseUrl}/${tenantId}/users/${userId}/files/${userFileId}`, { observe: 'response' });
  }

  downloadUserFileContent$(tenantId: string, userId: string, userFileId: string): Observable<HttpResponse<any>> {
    return this._http.get(`${this._baseUrl}/${tenantId}/users/${userId}/files/${userFileId}/content`,
      { observe: 'response', responseType: 'blob' });
  }

  listUserData$(tenantId: string, params?: UserDataFilter): Observable<UserDataResponse> {
    return this._http.get<UserDataResponse>(`${this._baseUrl}/${tenantId}/user-data`, { params: this._commonService.buildParams(params) });
  }

  listUserDataByTag$(tenantId: string, params?: UserDataByTagFilter): Observable<UserDataByTagResponse> {
    return this._http.get<UserDataByTagResponse>(`${this._baseUrl}/${tenantId}/user-data-by-tag`, { params: this._commonService.buildParams(params) });
  }
}
