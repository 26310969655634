import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { DropdownOption } from './dropdown.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'dropdown-primary',
    templateUrl: './dropdown-primary.component.html',
    providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } }],
    styleUrls: ['./dropdown-primary.component.scss'],
    standalone: false
})
export class DropdownPrimaryComponent implements OnInit, OnChanges {

  @Input() selectedOption: DropdownOption;

  @Output() selectedOptionChange = new EventEmitter<DropdownOption>();
  @Input() options!: DropdownOption[];

  @Input() cssClass: string = 'btn-dropdown';
  @Input() iconClass: string = 'bi bi-chevron-down';
  @Input() cssTextClass: string = '';
  @Input() dropdownCssClass: string = '';

  @Input() emitNgOnInit: boolean = true;
  @Input() isDisabled: boolean = false;
  @Input() isStaticSelectOption: boolean = false;

  @Input() isMobile: boolean = false;

  @Input() activeScrollbar = false;

  selectedMobileOption: any;

  defaultOption: DropdownOption = {
    value: '',
    text: this._translateService.instant('SELECT_OPTION')
  };

  constructor(
    private _translateService: TranslateService
  ) { }

  ngOnInit(): void {
    if (this.emitNgOnInit && this.selectedOption) {
      this.selectedOptionChange.emit(this.selectedOption);
    }
  }

  ngOnChanges(): void {
    if (this.isMobile) {
      this.onMobileSelectChange();
    }
  }

  selectOption(option: DropdownOption): void {
    if (!this.isStaticSelectOption) {

      if (!this.selectedOption) {
        this.selectedOption = {
          value: '',
          text: ''
        };
      }

      this.selectedOption.value = option.value;
      this.selectedOption.text = option.text;
      this.selectedOption.extraField = option.extraField;

      this.selectedOptionChange.emit(this.selectedOption);
    } else {
      this.selectedOptionChange.emit(option);
    }

  }

  onMobileSelectChange(): void {
    if (this.options && this.selectedMobileOption) {
      const selected = this.options.find(option => option.value === this.selectedMobileOption);
      if (selected) {
        this.selectOption(selected);
      }
    }

    else if (this.options) {
      this.selectOption(this.options[0]);
    }
  }
}
